import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Container, Row, Col} from 'reactstrap'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const RecentPage = ({ data, location }) => (
  <Layout location={location}>
    <Helmet title="Aktuelles - Informationen und Ankündigungen" />

    <Container className="py-0 py-lg-4">
      <Row className="align-items-center pt-5 pt-lg-7">
        <Col>
          <h6 className="text-uppercase">Informationen und Ankündigungen</h6>
          <h1 className="mb-3 text-uppercase">Aktuelles</h1>
        </Col>
      </Row>

      <Row className="my-5">
        <Col>
          <h2 className="h4 text-uppercase">Austria Guide Kongress 2018</h2>
          <Row>
          {data.kongress2018.edges.map(({ node }, idx) => 
            <Col md="6" lg="4" className="mt-4" key={idx}><Img fluid={node.childImageSharp.fluid} alt="Austria Guide Konrgress" /></Col>
          )}
          </Row>
        </Col>
      </Row>

    </Container>
  </Layout>
)

export const query = graphql`
  query {
    kongress2018: allFile(
      filter: { 
        relativeDirectory: {  eq: "news" }
      }, 
      sort: { fields: [relativePath], order: ASC}
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 500, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default RecentPage;